/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */
$font-size-base: 14px;
$font-source-sans-pro: "Source Sans Pro", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$font-family-lato: "Lato", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
$font-family-raleway: "Raleway", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;

$nav-header-height: 56px;
$mobile-nav-header-height: 50px;
