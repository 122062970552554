/**
 * A mixin which helps you to add depth to elements according to the Google Material Design spec:
 * http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
 *
 * Please note that the values given in the specification cannot be used as is. To create the same visual experience
 * the blur parameter has to be doubled.
 *
 * Less Version Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
 *
 * .BoxShadowHelper(@level: 1){
 *    & when (@level = 1) {
 *        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 *    }
 *    & when (@level = 2) {
 *        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
 *    }
 *    & when (@level = 3) {
 *        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
 *    }
 *    & when (@level = 4) {
 *        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
 *    }
 *    & when (@level = 5) {
 *        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
 *    }
 * }
 *
 * SASS Version Author: Li-Wei, Lu (@bblurock)
 *
 * Example usage:
 * .card {
 *     width: 95px;
 *     height: 95px;
 *     background: #f4f4f4;
 *     -webkit-transition: all 250ms;
 *     -moz-transition: all 250ms;
 *     transition: all 250ms;
 *     @include BoxShadowHelper(1);
 *     &:hover {
 *         @include BoxShadowHelper(3);
 *         -webkit-transform: translateY(-5px);
 *         -moz-transform: translateY(-5px);
 *         transform: translateY(-5px);
 *     }
 * }
 *
 */

@mixin BoxShadowHelper($level: 1){
    @if $level == 1 {
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }
    @if $level == 2 {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
    @if $level == 3 {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    @if $level == 4 {
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    @if $level == 5 {
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }
}