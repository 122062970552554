$greyLight300: #061C3F;
$greyLight200: #595959;
$greyLight150: #828183;
$greyLight75: #8C9BAA;
$greyLight60: #F4F6F9;
$greyLight50: #fafafa;

$primaryGreen: #00B33B;
$secondaryGreen: #04844B;
$primaryYellow: #fcde79;
$secondaryYellow: #F0B021;

$vibrantRed: #DC354D;
$logoOrange: #F0B021;
$logoWhite: #EFF0F1;