@import '../../theme/variables.scss';
@import '../../theme/colors.scss';
@import '../../theme/breakpoint.scss';
@import '../../theme/cardshadows.scss';

$iconColor: #0279b3;
.menu {
  button {
    padding: 0!important;
    height: 24px!important;
  }
}

.routeMap {
  margin: 0!important;
  img {
    width: 100%;
  }
}

.buttonText {
  line-height: 27px;
  display: flex;
  align-items: center;
}

.mapFieldTitle {
  padding: 0.75rem;
  margin: 0;
  font-size: 0.75rem;
  color: $greyLight200;
  text-align: center;
}

.dayPlannerContainer {
  position: relative;
  margin: 0;
  list-style: none;
  padding: 0;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-radius: 4px;
  box-shadow: 1px 2px 24px rgba(0, 0, 0, 0.09);

  font-weight: 300;
  font-family: $font-family-lato;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;

  .closeButton {
    transition: fill 0.3s ease-in;
    fill: $greyLight150!important;
    width: 22px!important;
    height: 22px!important;
  }

  .closeButtonContainer {
    position: absolute;
    right: -20px;
    top: -36px;
    cursor: pointer;

    @include for-phone-only {
      right: -33px;
    }
  }

  .tripListItem {
    font-size: 0.875rem;
    transition: background 0.3s ease-in;
  }

  .tripListItem:nth-child(odd) {
    background: $greyLight50;
  }

  .upDownButton {
    fill: $greyLight150!important;
    top: 0;
  }

  .tripListItem:hover {
    background: darken(#ffffff, 1%);

    .upDownButton {
      fill: $greyLight300!important;
      transform: translateY(-2px) scale(1.1);
    }
  }

  .tripListItem:nth-child(odd):hover {
    background: darken($greyLight50, 1%);

    .upDownButton {
      fill: $greyLight300!important;
      transform: translateY(-2px) scale(1.1);
    }
  }

  .tripSummary {
    margin: 0;
    color: $secondaryGreen;
    line-height: 24px;
    font-weight: 700;
  }

  .overtimeWarning {
    color: $vibrantRed;
  }

  .tripListItemContent {
    display: flex;
    flex-direction: column;
    padding: 1.25rem 2rem 0 4rem;
  }

  .stepIcon, .startIcon, .endIcon, .carIcon, .infoIcon {
    position: absolute;
    left: 1rem;
    z-index: 1;
  }

  .startIcon, .endIcon {
    fill: $iconColor!important;
    border: 2px solid $iconColor;
    background: white;
    border-radius: 50%;
    left: -48px;
    top: -1px;
  }

  .stepIcon {
    border: 2px solid $iconColor;
    background: white;
    border-radius: 50%;
    left: -48px;
    top: -1px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $iconColor;
    font-weight: 700;
  }

  .infoIcon {
    top: 17px;
    fill: $iconColor;
    background: white;
    border-radius: 50%;
  }

  .drivingTime {
    color: $greyLight150;
    line-height: 25px;
    font-weight: 700;
    margin: 0;
    padding-bottom: 0.75rem;
  }

  .placePrefix {
    font-size: 14px;
  }

  .placeName, .placePrefix {
    line-height: 25px;
    color: $greyLight200;
    margin: 0;
  }

  .placeName {
    display: flex;
    cursor: pointer;
    font-weight: 700;
    transition: opacity 0.3 ease-in;
    color: darken($iconColor, 10%);

    .arrowRight {
      display: flex;
      align-self: center;
      transform: rotate(270deg);
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .destinationDuration {
    display: flex;
    align-items: center;
    margin: 0;
    color: $greyLight150;
  }

  .durationLabel {
    display: flex;
    align-items: center;
  }

  .timerIcon {
    margin-right: 4px;
    fill: $greyLight150;
  }

  .moveUpAndDownButtonContainer {
    position: absolute;
    right: -33px;
    bottom: 18px;
    cursor: pointer;

    .upDownButton {
      width: 22px!important;
      height: 22px!important;
    }

    &:active .closeButton{
      fill: $greyLight300!important;
    }
  }

  .content {
    position: relative;
    padding-bottom: 0.75rem;

    &::after {
      display: block;
      content: ' ';
      width: 1px;
      height: 100%;
      position: absolute;
      background-color: #DBDBDB;
      left: -36px;
      top: -1px;
    }
  }

  .addMorePlaceButtonContainer {
    padding: 1.25rem 4rem;
    background-image: url('../../assets/bg-swirl.png');
    background-repeat: repeat;
    box-shadow: inset 0px 0px 18px 7px rgba(110, 122, 137, 0.1);

    .floatingIcon {
      position: absolute;
      left: 29px;
      margin-top: 7px;
      animation: move 3s infinite ease-in-out;
    }
  }
}

@keyframes move {
  0%, 100% {
    left: 29px;
  }
  50% {
    left: 37px;
  }
}

.editOrSummary {
  width: 100%;

  &.hide {
    display: none;
  }
}

.clickToEditButton {
  padding: 0.75rem;
  width: 100%;
}

.destinationPreviewText {
  padding: 0.75rem;
  width: 100%;
  display: flex;

  .summaryText {
    font-weight: 300;
    color: $greyLight200;
    font-family: $font-family-lato;
    margin: 0;
    word-wrap: break-word;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.2px;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-self: center;
  }

  .locationOnIcon {
    padding: 10px;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    align-self: center;
  }
}

.checkbox {
  margin-bottom: 0.75rem;
}

.dayListItemContainer {
  font-size: 0.875rem;
  border-radius: 3px;
  margin-bottom: 0.75rem;
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  border: 1px solid #ccc;
  background: white;
  max-height: 300px;
  overflow: hidden;

  .collapseTitle {
    display: flex;
    align-items: center;
    padding: 0.75rem 0.75rem 0;

    span {
      flex: 1;
      align-items: center;
      font-weight: 700;
      display: flex;
    }
  }

  &.toggled {
    max-height: 5000px;
    transition: max-height 0.25s ease-in;
  }
}

.cardInner {
  position: relative;
  color: $greyLight75;
  font-size: 15px;
  padding: 0.75rem 0 0;
  margin: 0 0.75rem;
}

.summary {
  display: flex;
  flex-direction: column;
}

.photos {
  display: flex!important;
  width: 100%;
  overflow-y: scroll;
  margin-top: 0.5rem;
  background: $greyLight50;
  max-width: 460px;

  & > div {
    margin: auto;
  }

  .photo {
    width: 56px;
    height: 80px;
    margin: auto 1px;
    flex-shrink: 0;
  }

  @include for-desktop-up {
    display: block;

    & > div {
      float: left;
    }

    .photo {
      margin: 1px;
    }
  }
}

.cardInnerPath {
  background: repeating-linear-gradient($secondaryGreen, $secondaryGreen 3px, transparent 3px, transparent 6px);
  border-color: $secondaryGreen;
  position: absolute;
  left: 10px;
  top: 40px;
  min-width: 2px!important;
  width: 2px!important;
  height: 28px;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: -3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid;
    border-color: inherit;
  }

  &:before {
    top: -11px;
  }
  &:after {
    bottom: -9px;
  }
}

.cardInnerFromTo {
  padding: 8px 0 0 26px;
  color: $greyLight200;
  font-size: 15px;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 26px;
    top: 49px;
    width: 90%;
    height: 1px;
    background: #ECECEC;
  }

  p:first-child {
    margin-bottom: 17px;
  }

  .innerPlaceHolder {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .editIcon {
    height: 16px!important;
    margin-right: 0.5rem;
  }

  .addCircle {
    color: $primaryYellow!important;
    margin-right: 0.5rem;
  }
}

.collapsibleWrapper {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.collapsibleWrapper:after {
  content: '';
  height: 50px;
  transition: height 0.1s cubic-bezier(.9,.03,.69,.22), max-height 0s 0.1s cubic-bezier(.9,.03,.69,.22);
  max-height: 0px;
}
.collapsible {
  transition: margin-bottom 0.1s ccubic-bezier(.9,.03,.69,.22);
  margin-bottom: 0;
  max-height: 1000000px;
  width: 100%;
}
.collapsibleWrapper.unToggled > .collapsible {
  margin-bottom: -2000px;
  transition: margin-bottom 0.1s cubic-bezier(.9,.03,.69,.22),
              visibility 0s 0.1s, max-height 0s 0.1s;
  visibility: hidden;
  max-height: 0;
}
.collapsibleWrapper.unToggled:after {
  height: 0;
  transition: height 0.1s cubic-bezier(.9,.03,.69,.22);
  max-height: 50px;
}
