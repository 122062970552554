/**
 *  Define scss variables here.
 *
 *  Available options for Bootstrap:
 *  http://getbootstrap.com/customize/
 *
 */
/**
 * A mixin which helps you to add depth to elements according to the Google Material Design spec:
 * http://www.google.com/design/spec/layout/layout-principles.html#layout-principles-dimensionality
 *
 * Please note that the values given in the specification cannot be used as is. To create the same visual experience
 * the blur parameter has to be doubled.
 *
 * Less Version Author: Florian Kutschera (@gefangenimnetz), Conceptboard GmbH (@conceptboardapp)
 *
 * .BoxShadowHelper(@level: 1){
 *    & when (@level = 1) {
 *        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 *    }
 *    & when (@level = 2) {
 *        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
 *    }
 *    & when (@level = 3) {
 *        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
 *    }
 *    & when (@level = 4) {
 *        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
 *    }
 *    & when (@level = 5) {
 *        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
 *    }
 * }
 *
 * SASS Version Author: Li-Wei, Lu (@bblurock)
 *
 * Example usage:
 * .card {
 *     width: 95px;
 *     height: 95px;
 *     background: #f4f4f4;
 *     -webkit-transition: all 250ms;
 *     -moz-transition: all 250ms;
 *     transition: all 250ms;
 *     @include BoxShadowHelper(1);
 *     &:hover {
 *         @include BoxShadowHelper(3);
 *         -webkit-transform: translateY(-5px);
 *         -moz-transform: translateY(-5px);
 *         transform: translateY(-5px);
 *     }
 * }
 *
 */
.menu button {
  padding: 0 !important;
  height: 24px !important;
}

.routeMap {
  margin: 0 !important;
}

.routeMap img {
  width: 100%;
}

.buttonText {
  line-height: 27px;
  display: flex;
  align-items: center;
}

.mapFieldTitle {
  padding: 0.75rem;
  margin: 0;
  font-size: 0.75rem;
  color: #595959;
  text-align: center;
}

.dayPlannerContainer {
  position: relative;
  margin: 0;
  list-style: none;
  padding: 0;
  border: 1px solid rgba(204, 204, 204, 0.3);
  border-radius: 4px;
  box-shadow: 1px 2px 24px rgba(0, 0, 0, 0.09);
  font-weight: 300;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
}

.dayPlannerContainer .closeButton {
  transition: fill 0.3s ease-in;
  fill: #828183 !important;
  width: 22px !important;
  height: 22px !important;
}

.dayPlannerContainer .closeButtonContainer {
  position: absolute;
  right: -20px;
  top: -36px;
  cursor: pointer;
}

@media (max-width: 599px) {
  .dayPlannerContainer .closeButtonContainer {
    right: -33px;
  }
}

.dayPlannerContainer .tripListItem {
  font-size: 0.875rem;
  transition: background 0.3s ease-in;
}

.dayPlannerContainer .tripListItem:nth-child(odd) {
  background: #fafafa;
}

.dayPlannerContainer .upDownButton {
  fill: #828183 !important;
  top: 0;
}

.dayPlannerContainer .tripListItem:hover {
  background: #fcfcfc;
}

.dayPlannerContainer .tripListItem:hover .upDownButton {
  fill: #061C3F !important;
  transform: translateY(-2px) scale(1.1);
}

.dayPlannerContainer .tripListItem:nth-child(odd):hover {
  background: #f7f7f7;
}

.dayPlannerContainer .tripListItem:nth-child(odd):hover .upDownButton {
  fill: #061C3F !important;
  transform: translateY(-2px) scale(1.1);
}

.dayPlannerContainer .tripSummary {
  margin: 0;
  color: #04844B;
  line-height: 24px;
  font-weight: 700;
}

.dayPlannerContainer .overtimeWarning {
  color: #DC354D;
}

.dayPlannerContainer .tripListItemContent {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 2rem 0 4rem;
}

.dayPlannerContainer .stepIcon, .dayPlannerContainer .startIcon, .dayPlannerContainer .endIcon, .dayPlannerContainer .carIcon, .dayPlannerContainer .infoIcon {
  position: absolute;
  left: 1rem;
  z-index: 1;
}

.dayPlannerContainer .startIcon, .dayPlannerContainer .endIcon {
  fill: #0279b3 !important;
  border: 2px solid #0279b3;
  background: white;
  border-radius: 50%;
  left: -48px;
  top: -1px;
}

.dayPlannerContainer .stepIcon {
  border: 2px solid #0279b3;
  background: white;
  border-radius: 50%;
  left: -48px;
  top: -1px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0279b3;
  font-weight: 700;
}

.dayPlannerContainer .infoIcon {
  top: 17px;
  fill: #0279b3;
  background: white;
  border-radius: 50%;
}

.dayPlannerContainer .drivingTime {
  color: #828183;
  line-height: 25px;
  font-weight: 700;
  margin: 0;
  padding-bottom: 0.75rem;
}

.dayPlannerContainer .placePrefix {
  font-size: 14px;
}

.dayPlannerContainer .placeName, .dayPlannerContainer .placePrefix {
  line-height: 25px;
  color: #595959;
  margin: 0;
}

.dayPlannerContainer .placeName {
  display: flex;
  cursor: pointer;
  font-weight: 700;
  transition: opacity 0.3 ease-in;
  color: #015781;
}

.dayPlannerContainer .placeName .arrowRight {
  display: flex;
  align-self: center;
  transform: rotate(270deg);
}

.dayPlannerContainer .placeName:hover {
  opacity: 0.8;
}

.dayPlannerContainer .destinationDuration {
  display: flex;
  align-items: center;
  margin: 0;
  color: #828183;
}

.dayPlannerContainer .durationLabel {
  display: flex;
  align-items: center;
}

.dayPlannerContainer .timerIcon {
  margin-right: 4px;
  fill: #828183;
}

.dayPlannerContainer .moveUpAndDownButtonContainer {
  position: absolute;
  right: -33px;
  bottom: 18px;
  cursor: pointer;
}

.dayPlannerContainer .moveUpAndDownButtonContainer .upDownButton {
  width: 22px !important;
  height: 22px !important;
}

.dayPlannerContainer .moveUpAndDownButtonContainer:active .closeButton {
  fill: #061C3F !important;
}

.dayPlannerContainer .content {
  position: relative;
  padding-bottom: 0.75rem;
}

.dayPlannerContainer .content::after {
  display: block;
  content: ' ';
  width: 1px;
  height: 100%;
  position: absolute;
  background-color: #DBDBDB;
  left: -36px;
  top: -1px;
}

.dayPlannerContainer .addMorePlaceButtonContainer {
  padding: 1.25rem 4rem;
  background-image: url("../../assets/bg-swirl.png");
  background-repeat: repeat;
  box-shadow: inset 0px 0px 18px 7px rgba(110, 122, 137, 0.1);
}

.dayPlannerContainer .addMorePlaceButtonContainer .floatingIcon {
  position: absolute;
  left: 29px;
  margin-top: 7px;
  animation: move 3s infinite ease-in-out;
}

@keyframes move {
  0%, 100% {
    left: 29px;
  }
  50% {
    left: 37px;
  }
}

.editOrSummary {
  width: 100%;
}

.editOrSummary.hide {
  display: none;
}

.clickToEditButton {
  padding: 0.75rem;
  width: 100%;
}

.destinationPreviewText {
  padding: 0.75rem;
  width: 100%;
  display: flex;
}

.destinationPreviewText .summaryText {
  font-weight: 300;
  color: #595959;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
  margin: 0;
  word-wrap: break-word;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-self: center;
}

.destinationPreviewText .locationOnIcon {
  padding: 10px;
  margin-right: 0.5rem;
  display: flex;
  justify-content: center;
  align-self: center;
}

.checkbox {
  margin-bottom: 0.75rem;
}

.dayListItemContainer {
  font-size: 0.875rem;
  border-radius: 3px;
  margin-bottom: 0.75rem;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 1px solid #ccc;
  background: white;
  max-height: 300px;
  overflow: hidden;
}

.dayListItemContainer .collapseTitle {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.75rem 0;
}

.dayListItemContainer .collapseTitle span {
  flex: 1;
  align-items: center;
  font-weight: 700;
  display: flex;
}

.dayListItemContainer.toggled {
  max-height: 5000px;
  transition: max-height 0.25s ease-in;
}

.cardInner {
  position: relative;
  color: #8C9BAA;
  font-size: 15px;
  padding: 0.75rem 0 0;
  margin: 0 0.75rem;
}

.summary {
  display: flex;
  flex-direction: column;
}

.photos {
  display: flex !important;
  width: 100%;
  overflow-y: scroll;
  margin-top: 0.5rem;
  background: #fafafa;
  max-width: 460px;
}

.photos > div {
  margin: auto;
}

.photos .photo {
  width: 56px;
  height: 80px;
  margin: auto 1px;
  flex-shrink: 0;
}

@media (min-width: 1200px) {
  .photos {
    display: block;
  }
  .photos > div {
    float: left;
  }
  .photos .photo {
    margin: 1px;
  }
}

.cardInnerPath {
  background: repeating-linear-gradient(#04844B, #04844B 3px, transparent 3px, transparent 6px);
  border-color: #04844B;
  position: absolute;
  left: 10px;
  top: 40px;
  min-width: 2px !important;
  width: 2px !important;
  height: 28px;
}

.cardInnerPath:before, .cardInnerPath:after {
  content: "";
  position: absolute;
  left: -3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 2px solid;
  border-color: inherit;
}

.cardInnerPath:before {
  top: -11px;
}

.cardInnerPath:after {
  bottom: -9px;
}

.cardInnerFromTo {
  padding: 8px 0 0 26px;
  color: #595959;
  font-size: 15px;
  cursor: pointer;
}

.cardInnerFromTo:before {
  content: "";
  position: absolute;
  left: 26px;
  top: 49px;
  width: 90%;
  height: 1px;
  background: #ECECEC;
}

.cardInnerFromTo p:first-child {
  margin-bottom: 17px;
}

.cardInnerFromTo .innerPlaceHolder {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cardInnerFromTo .editIcon {
  height: 16px !important;
  margin-right: 0.5rem;
}

.cardInnerFromTo .addCircle {
  color: #fcde79 !important;
  margin-right: 0.5rem;
}

.collapsibleWrapper {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.collapsibleWrapper:after {
  content: '';
  height: 50px;
  transition: height 0.1s cubic-bezier(0.9, 0.03, 0.69, 0.22), max-height 0s 0.1s cubic-bezier(0.9, 0.03, 0.69, 0.22);
  max-height: 0px;
}

.collapsible {
  transition: margin-bottom 0.1s ccubic-bezier(0.9, 0.03, 0.69, 0.22);
  margin-bottom: 0;
  max-height: 1000000px;
  width: 100%;
}

.collapsibleWrapper.unToggled > .collapsible {
  margin-bottom: -2000px;
  transition: margin-bottom 0.1s cubic-bezier(0.9, 0.03, 0.69, 0.22), visibility 0s 0.1s, max-height 0s 0.1s;
  visibility: hidden;
  max-height: 0;
}

.collapsibleWrapper.unToggled:after {
  height: 0;
  transition: height 0.1s cubic-bezier(0.9, 0.03, 0.69, 0.22);
  max-height: 50px;
}
